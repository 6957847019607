import React, {useEffect} from "react";
import {useRouter} from "next/router";
import {useSelector, useDispatch} from "react-redux";
import {setPopup} from "../../store/general";
import {
    Facebook,
    Twitter,
    Linkedin,
    Google,
    Github,
    FailSvg,
} from "../../svg";
import {FormattedMessage} from "react-intl";
import {
    cartRemoveItemAfterLogin,
    cartAddItemAfterLogin,
} from "../../store/cart";
import {useToast} from "../../atoms/Toast/useToast";
import {IState} from "../../types/state";
import {url} from "../../services/utils";
import {submitCardProductsType} from "./types";

export const SocialLogin = () => {
    const dispatch = useDispatch()
    const router = useRouter();
    const toast = useToast();
    const socialEnum = {socialItem: '1'}
    const config = useSelector((state: IState) => state.general.coreConfigs)
    const httpUrl = useSelector((state: IState) => state.general.dbName)
    const cartToken = useSelector((state: IState) => state.cartToken);
    const cart = useSelector((state: IState) => state.cart);
    const selectedData = useSelector((state: IState) => state.locale.code);
    const customerLoginWithProvider = (provider: string) => {
        try {
            fetch(`https://${httpUrl}/api/customer/social-login/${provider}`)
                .then((response) => response.json())
                .then((res) => {
                    window.location = res.url
                })
        } catch (err) {
            console.log(err)
        }
    }

    const submitCartData = (products: submitCardProductsType, token: string | string[] | undefined) => {
        if (cart.items.length > 0 && products.data !== null) {
            cart.items.map((product: { product: { id: number; }; quantity: number; id: number; }) => {
                let pro = products.data.items?.find(
                    (item) => item.product.id == product.product.id
                );
                if (pro == undefined) {
                    fetch(`https://${httpUrl}/api/checkout/cart/add/${product.product.id}?product_id=${product.product.id}&quantity=${product.quantity
                        }&api_token=${cartToken.cartToken}${
                            token ? `&token=${token}` : ""
                        }`,
                        {method: "POST"}
                    )
                        .then((responce) => responce.json())
                        .then((res) => console.log(res))
                        .catch((err) => console.error(err));
                } else {
                    cartRemoveItemAfterLogin(product.id, pro.product.id, dispatch);
                }
            });
        } else if (cart.items.length > 0 && products.data === null) {
            cart.items.map((product) => {

                fetch(
                    `${url}/api/checkout/cart/add/${product.product.id}?product_id=${
                        product.product.id
                    }&quantity=${product.quantity}&api_token=${cartToken.cartToken}${
                        router.query.token ? `&token=${router.query.token}` : ""
                    }`,
                    {method: "POST"}
                )
                    .then((responce) => responce.json())
                    .then((res) => {
                    })
                    .catch((err) => console.error(err));
            });
        }

        if (products.data !== null && products.data.items.length > 0) {
            products.data.items.map((product) => {
                let customer;
                cartAddItemAfterLogin(
                    product.product,
                    [],
                    product.quantity,
                    cartToken,
                    customer,
                    selectedData,
                    dispatch,
                    products
                );
            });
        }
    };

    useEffect(() => {
        if (router.query.token) {
            dispatch({type: "AUTHENTICATED", payload: true});
            dispatch({type: "CUSTOMER_TOKEN", payload: router.query.token});
            setPopup(false);
            router.push("/account/profile/");
            fetch(
                `https://${httpUrl}/api/checkout/cart?token=${router.query.token}&api_token=${cartToken.cartToken}`
            )
                .then((responce) => responce.json())
                .then((resUser) => {
                    if (resUser) {
                        submitCartData(resUser, router.query.token);
                    } else {
                        toast.open(
                            <span className="d-flex chek-fms">
                                <FailSvg/>
                                <FormattedMessage
                                    id="invalid_email_or_password"
                                    defaultMessage="Something Went Wrong"
                                />
                            </span>
                        );
                    }
                }).catch((err) => console.error(err));
        }
    }, [router]);

    const socials = [
        {
            title: "enable_facebook",
            Component: <Facebook/>,
            provider: "facebook",
        },
        {
            title: "enable_google",
            Component: <Google/>,
            provider: "google",
        },
        {
            title: "enable_twitter",
            Component: <Twitter/>,
            provider: "twitter",
        },
        {
            title: "enable_linkedin",
            Component: <Linkedin/>,
            provider: "linkedin",
        },
        {
            title: "enable_github",
            Component: <Github/>,
            provider: "github",
        },
    ];


    const socialLinkStatus = socials.some(item => config[`customer_settings_social_login_${item.title}`] == socialEnum.socialItem)
    return (
        <>
            {
                socialLinkStatus &&
                <div className="socials_content">
                    <div className="social-line-block">
                        <div className="social_line"></div>
                        <span className="social_line-span">
                            <FormattedMessage id="sign_in_with" defaultMessage="or sign in with"/>
                        </span>
                        <div className="social_line"></div>
                    </div>
                    <div className="social-links_line">
                        {
                            socials.map((social, index: number) => (
                                config[`customer_settings_social_login_${social.title}`] === socialEnum.socialItem && (
                                    <div key={index} className="links_icons"
                                         onClick={() => customerLoginWithProvider(social.provider)}>
                                        {social.Component}
                                    </div>
                                )
                            ))
                        }
                    </div>
                </div>
            }

        </>
    )
}