import React, {FC} from 'react';
import {FormattedMessage} from 'react-intl'
import {idGen} from "../services/utils";
import {width} from "@mui/system";

interface IProps {
    id?: string,
    type?: string,
    inpValue?: string,
    label?: string,
    value?: string,
    className?: string,
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    withStar?: boolean,
    name?: string,
    defaultValue?: string,
    isUsed?: string,
    code?: string,
    style?: {
        width: string
    },
    autoComplete?: string,
    required?: string,
}

export const CustomTextField: FC<IProps> = ({
    type = "",
    inpValue = "",
    label = "",
    value = "",
    className = "",
    handleChange,
    withStar = false,
    defaultValue = "",
    isUsed = "",
    code,
    required,
    ...props
}) => {

    let newType = type ? type : "text";
    let customClassName = className ? className : "";
    let generatedId = props.name + idGen();
    const LabelName = <FormattedMessage id={value || "value"} defaultMessage={label || ""}/>
    return (
        <div className={`custom_input_checkout ${customClassName}`}>
            <input
                className="custom_input_field"
                id={generatedId}
                type={newType}
                placeholder=" "
                // required={true}
                autoComplete="off"
                value={isUsed ? code : inpValue}
                onChange={handleChange}
                // defaultValue={defaultValue}
                disabled={!!isUsed}
                {...props}
                required={!!required}
            />
            <label className="custom_input_label" htmlFor={generatedId}>{LabelName}{withStar &&
                <span className="red-fms"> *</span>}</label>
        </div>
    )
}

